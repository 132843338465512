import { yupResolver } from '@hookform/resolvers/yup';
import { Email as EmailIcon } from '@mui/icons-material';
import {
  DialogContent as MuiDialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import ErrorIcon from '@/assets/error.svg';
import CompanyInput from '@/components/ui/CompanyInput/CompanyInput';
import CountryPhonePrefixSelect from '@/components/ui/CountryPhonePrefixSelect/CountryPhonePrefixSelect';
import Input from '@/components/ui/Input/Input';
import api from '@/helpers/api';
import {
  trackContactFormEmailClick,
  trackContactFormSuccess,
} from '@/helpers/dataLayer';
import { getPayload } from '@/helpers/mappers/sendEmail';
import { isEmailValid, validatePhoneNumber } from '@/helpers/validators';
import useFormatMessage from '@/hooks/useFormatMessage';
import { useSourceContext } from '@/hooks/useSourceContext';

import StatusContent from '../StatusContent/StatusContent';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  LinkItem,
  LoadingButton,
  SuccessIcon,
} from './DialogContent.style';

const initialData = {
  forename: '',
  surname: '',
  company: '',
  phone: {
    countryCode: 'DE',
    number: '',
  },
  email: '',
  message: '',
  subject: null,
  marketingConsent: true,
};

const getValidationSchema = formatMessage => {
  const formatRequiredErrorMessage = inputName =>
    formatMessage('form_mandatoryInput', {
      inputName: formatMessage(inputName),
    });

  return Yup.object({
    forename: Yup.string()
      .trim()
      .required(formatRequiredErrorMessage('contactForm_name')),
    surname: Yup.string()
      .trim()
      .required(formatRequiredErrorMessage('contactForm_surname')),
    company: Yup.string()
      .trim()
      .required(formatRequiredErrorMessage('contactForm_company')),
    phone: Yup.object({
      countryCode: Yup.string().required(''),
      number: Yup.string()
        .trim()
        .test(
          'validPhone',
          formatMessage('contactForm_phoneError'),
          (value, ctx) => validatePhoneNumber(value, ctx.parent.countryCode)
        ),
    }),
    email: Yup.string()
      .trim()
      .test('validEmail', formatMessage('contactForm_emailError'), value =>
        isEmailValid(value)
      )
      .required(formatRequiredErrorMessage('contactForm_email')),
    message: Yup.string().required(
      formatRequiredErrorMessage('contactForm_message')
    ),
    marketingConsent: Yup.boolean(),
  });
};

const DialogContent = ({
  email: parentEmail,
  emailSubject: parentEmailSubject,
  emailBody: parentEmailBody,
  defaultValues,
  onClose,
  onChange: parentOnChange,
  onEmailSent,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const formatMessage = useFormatMessage();
  const { source } = useSourceContext();

  const email = parentEmail || formatMessage('header_email');
  const emailSubject =
    parentEmailSubject || formatMessage('header_email_subject');
  const emailBody = parentEmailBody || formatMessage('header_email_body');

  const {
    handleSubmit,
    control,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: defaultValues || initialData,
    mode: 'onTouched',
    resolver: yupResolver(getValidationSchema(formatMessage)),
  });

  const handleSendEmail = async values => {
    if (values.marketingConsent) {
      try {
        await api.subscribeNewsletter(values.email);
      } catch (err) {
        // no error handling
      }
    }

    try {
      const payload = getPayload(values, source);
      const response = await api.sendEmail(payload);

      if (response.status === 204) {
        trackContactFormSuccess();
        setShowSuccess(true);
        onEmailSent?.();
      } else {
        setShowError(true);
      }
    } catch {
      setShowError(true);
    }
  };

  const handleOnChange = ({ inputName, value, onChange }) => {
    if (onChange) {
      onChange(value);
    }
    parentOnChange?.(inputName, value?.target?.value ?? value);
  };

  if (showSuccess) {
    return (
      <StatusContent
        title={formatMessage('contactForm_sucessHead')}
        mainText={formatMessage('contactForm_sucessSubhead')}
        onButtonClick={onClose}
        buttonText={formatMessage('contactForm_sucessButton')}
        image={<SuccessIcon />}
      />
    );
  }

  if (showError) {
    return (
      <StatusContent
        title={formatMessage('contactForm_errorHead')}
        mainText={formatMessage('contactForm_errorSubhead', {
          supportEmail: formatMessage('header_email'),
          supportPhone: formatMessage('team_phone'),
        })}
        onButtonClick={onClose}
        buttonText={formatMessage('contactForm_errorButton')}
        image={<ErrorIcon />}
      />
    );
  }

  return (
    <>
      <DialogTitle align="center">
        {formatMessage('contactForm_head')}
      </DialogTitle>
      <MuiDialogContent>
        <Stack spacing={2} mb={2} alignItems="center">
          <Button
            size="large"
            color="primaryLight"
            component="a"
            href={encodeURI(
              `mailto:${email}?subject=${emailSubject}&body=${emailBody}`
            )}
            startIcon={<EmailIcon />}
            onClick={trackContactFormEmailClick}>
            {formatMessage('header_email')}
          </Button>
          <Divider sx={{ width: 300 }}>
            <Typography variant="caption">
              {formatMessage('contactForm_subhead')}
            </Typography>
          </Divider>
        </Stack>
        <Form noValidate onSubmit={handleSubmit(handleSendEmail)}>
          <Stack direction={{ sm: 'row', xs: 'column' }} spacing={{ sm: 1.5 }}>
            <Controller
              name="forename"
              control={control}
              render={({
                field: { ref, onChange, name, ...field },
                fieldState: { error },
              }) => (
                <Input
                  label={formatMessage('contactForm_name')}
                  error={!!error}
                  fullWidth
                  inputRef={ref}
                  required
                  helperText={error?.message || ' '}
                  onChange={value => {
                    handleOnChange({ onChange, inputName: name, value });
                  }}
                  name={name}
                  {...field}
                />
              )}
            />
            <Controller
              name="surname"
              control={control}
              render={({
                field: { ref, onChange, name, ...field },
                fieldState: { error },
              }) => (
                <Input
                  label={formatMessage('contactForm_surname')}
                  error={!!error}
                  fullWidth
                  inputRef={ref}
                  required
                  helperText={error?.message || ' '}
                  onChange={value => {
                    handleOnChange({ onChange, inputName: name, value });
                  }}
                  name={name}
                  {...field}
                />
              )}
            />
          </Stack>
          <Controller
            name="company"
            control={control}
            render={({
              field: { ref, onChange, name, ...field },
              fieldState: { error },
            }) => (
              <CompanyInput
                label={formatMessage('contactForm_company')}
                error={!!error}
                required
                inputRef={ref}
                onChange={value => {
                  handleOnChange({
                    onChange,
                    inputName: name,
                    value: value.value,
                  });
                }}
                showAddress={false}
                helperText={error?.message || ' '}
                name={name}
                {...field}
              />
            )}
          />
          <Stack direction="row" spacing={1.5} mb={0.5}>
            <Controller
              name="phone.countryCode"
              control={control}
              render={({
                field: { ref, onChange, name, ...field },
                fieldState: { error },
              }) => (
                <CountryPhonePrefixSelect
                  inputRef={ref}
                  error={!!error}
                  onChange={value => {
                    handleOnChange({ onChange, inputName: name, value });
                    trigger('phone.number');
                  }}
                  name={name}
                  {...field}
                />
              )}
            />
            <Controller
              name="phone.number"
              control={control}
              render={({
                field: { ref, onChange, name, ...field },
                fieldState: { error },
              }) => (
                <Input
                  inputMode="tel"
                  fullWidth
                  label={formatMessage('contactForm_phone')}
                  inputRef={ref}
                  error={!!error}
                  helperText={error?.message || ' '}
                  onChange={value => {
                    handleOnChange({ onChange, inputName: name, value });
                  }}
                  name={name}
                  {...field}
                />
              )}
            />
          </Stack>
          <Controller
            name="email"
            control={control}
            render={({
              field: { ref, onChange, name, ...field },
              fieldState: { error },
            }) => (
              <Input
                label={formatMessage('contactForm_email')}
                error={!!error}
                required
                fullWidth
                inputRef={ref}
                inputMode="email"
                helperText={error?.message || ' '}
                onChange={value => {
                  handleOnChange({ onChange, inputName: name, value });
                }}
                name={name}
                {...field}
              />
            )}
          />
          <Controller
            name="message"
            control={control}
            render={({
              field: { onChange, value, name, ...field },
              fieldState: { error },
            }) => (
              <Input
                name={name}
                label={formatMessage('contactForm_message')}
                value={value}
                onChange={e => {
                  handleOnChange({
                    onChange,
                    inputName: name,
                    value: e.target.value,
                  });
                }}
                fullWidth
                multiline
                rows={6}
                autoComplete="off"
                error={!!error}
                helperText={error?.message || ' '}
                required
                {...field}
              />
            )}
          />
          <Controller
            name="marketingConsent"
            control={control}
            render={({ field: { ref, value, ...field } }) => (
              <Checkbox
                label={formatMessage('contactForm_marketing_consent', {
                  link: chunks => (
                    <a
                      href="https://www.klickrent.de/tos"
                      target="_blank"
                      rel="noreferrer noopener">
                      {chunks}
                    </a>
                  ),
                })}
                inputRef={ref}
                checked={value}
                {...field}
              />
            )}
          />
          <Alert severity="info">
            {formatMessage('contactForm_privacy', {
              link: chunks => (
                <LinkItem
                  component={Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.klickrent.de/data-privacy">
                  {chunks}
                </LinkItem>
              ),
            })}
          </Alert>
          <Stack spacing={2} mt={4} alignItems="center">
            <LoadingButton
              size="large"
              type="submit"
              fullWidth
              loading={isSubmitting}>
              {formatMessage('contactForm_CTA')}
            </LoadingButton>
            <Button
              size="large"
              color="primary"
              variant="text"
              onClick={onClose}
              fullWidth>
              {formatMessage('contactForm_cancel')}
            </Button>
          </Stack>
        </Form>
      </MuiDialogContent>
    </>
  );
};

DialogContent.propTypes = {
  email: PropTypes.string,
  emailSubject: PropTypes.string,
  emailBody: PropTypes.string,
  defaultValues: PropTypes.shape({
    forename: PropTypes.string,
    surname: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
    }),
    email: PropTypes.string,
    message: PropTypes.string,
    marketingConsent: PropTypes.bool,
  }),
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onEmailSent: PropTypes.func,
};

export default DialogContent;
