import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';

import DialogContent from './DialogContent/DialogContent';

const ContactFormDialog = ({
  open,
  email,
  emailSubject,
  emailBody,
  defaultValues,
  onClose,
  onChange,
  onEmailSent,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      PaperProps={{ 'data-cy': 'headerEmailDialog' }}>
      <IconButton
        aria-label={formatMessage('contactForm_cancel')}
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          borderRadius: '50%',
        }}
        data-cy="headerEmailClose">
        <CloseIcon />
      </IconButton>
      {open && (
        <DialogContent
          email={email}
          emailSubject={emailSubject}
          emailBody={emailBody}
          defaultValues={defaultValues}
          onClose={onClose}
          onChange={onChange}
          onEmailSent={onEmailSent}
        />
      )}
    </Dialog>
  );
};

ContactFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  email: PropTypes.string,
  emailSubject: PropTypes.string,
  emailBody: PropTypes.string,
  defaultValues: PropTypes.shape({
    forename: PropTypes.string,
    surname: PropTypes.string,
    company: PropTypes.string,
    phone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
    }),
    email: PropTypes.string,
    message: PropTypes.string,
    marketingConsent: PropTypes.bool,
  }),
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onEmailSent: PropTypes.func,
};

export default ContactFormDialog;
